import { contractAbi, stakingAbi, pairAbi, skpAbi } from './ABI/ABI';
const Web3 = require('web3')

let web3;
let connectedAddress:string;

//MAINNET
const contractAddress = '0xE1B66A1F5eF08eb246890c3fAB259A2656653E7F';
const skpFarmAddress = '0xdE492852E4F5440d5538A4DB43317837989940E0';
const skpTokenAddress = '0xE0Be422249882F2236Dc17599D279e1F8337556E';
let bnbPairAddress = '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE';

let contractObj;
let bnbPairObj;
let skpFarmObj;
let skpTokenObj;

const Web3Functions = {

    async setupConnnection(address: string) {
        //MAINNET
        const rpcURL = 'https://bsc-dataseed.binance.org/'

        try {
            connectedAddress = address;

            web3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
            web3.eth.defaultAccount = web3.eth.accounts[0];

            contractObj = new web3.eth.Contract(JSON.parse(contractAbi), contractAddress);
            bnbPairObj = new web3.eth.Contract(JSON.parse(pairAbi), bnbPairAddress);
            skpFarmObj = new web3.eth.Contract(JSON.parse(skpAbi), skpFarmAddress);
            skpTokenObj = new web3.eth.Contract(JSON.parse(contractAbi), skpTokenAddress);


        } catch (err) {
            console.log(err);
        }
    },
    async getAllowance(poolAddress: string) {
        if (connectedAddress) {

        }
    },
    async getBnbPrice() {
        let res = await bnbPairObj.methods.getReserves().call();

        let ethInPool = res[1] / 1e18;
        let usdtInPool = res[0] / 1e18;

        let prePrice = ethInPool / usdtInPool;
        let price = 1 / prePrice;

        return price;
    },
    async getTokenPrice(tokenAddress: string) {
        const tokenPairObj = new web3.eth.Contract(JSON.parse(pairAbi), tokenAddress);
        let res = await tokenPairObj.methods.getReserves().call();

        let bnbInPool = res[0] / 1e18;
        let tokensInPool = res[1] / 1e18;

        let bnbPrice = await this.getBnbPrice();

        let price = (bnbInPool * bnbPrice) / tokensInPool;

        return price;
    },
    async getData(poolAddress: string, tokenAddress: string, tokenPoolAddress) {
        let poolObj = new web3.eth.Contract(JSON.parse(stakingAbi), poolAddress);
        const tokenObj = new web3.eth.Contract(JSON.parse(contractAbi), tokenAddress);

        let data = {
            apy: 0,
            exitPenalty: 0,
            totalStaked: 0,
            userStaked: 0,
            unlockTime: 0,
            allowance: 0,
            tokenBalance: 0,
            pendingRewards: 0,
            withdrawFee: 0,
            tokenPrice: 0
        }

        if (connectedAddress) {
            try {
                let apy = await poolObj.methods.apy().call();
                data.apy = parseInt(apy);
            } catch (err) {
                data.apy = 0;
            }

            try {
                let exitPenalty = await poolObj.methods.exitPenaltyPerc().call();
                data.exitPenalty = parseInt(exitPenalty);
            } catch {
                data.exitPenalty = 0;
            }

            try {
                data.tokenPrice = await this.getTokenPrice(tokenPoolAddress);
            } catch (err) {
                console.log('Error fetching data: ' + err);
            }

            try {
                let totalStaked = await poolObj.methods.totalStaked().call();
                data.totalStaked = parseFloat(totalStaked) / 1e18;
            } catch {
                data.totalStaked = 0;
            }

            try {
                let userStaked = await poolObj.methods.userInfo(connectedAddress).call();
                data.userStaked = userStaked.amount / 1e18;
            } catch {
                data.userStaked = 0;
            }

            try {
                let unlockTime = await poolObj.methods.holderUnlockTime(connectedAddress).call();
                data.unlockTime = parseInt(unlockTime);
            } catch {
                data.unlockTime = 0;
            }

            try {
                let pendingRewards = await poolObj.methods.pendingReward(connectedAddress).call();
                data.pendingRewards = parseFloat(pendingRewards) / 1e18;
            } catch {
                data.pendingRewards = 0;
            }

            try {
                let withdrawFee = await poolObj.methods.getWithdrawPenaltyFee(connectedAddress).call();
                data.withdrawFee= parseFloat(withdrawFee) / 1e18;
            } catch {
                data.withdrawFee = 0;
            }

            try {
                let balance = await tokenObj.methods.balanceOf(connectedAddress).call();
                data.tokenBalance = parseFloat(balance) / 1e18;
            } catch (err) {
                data.tokenBalance = 0;
            }

            try {
                let allowance = await tokenObj.methods.allowance(connectedAddress, poolAddress).call();
                data.allowance = parseFloat(allowance) / 1e18;
            } catch (err) {
                data.allowance = 0;
            }
        }
        return data;
    },
    async getSKPBalance() {
        if(connectedAddress) {
             let res = await skpTokenObj.methods.balanceOf(connectedAddress).call();

             return res / 1e18;
        }
    },
    async getSKPUnlock() {
        if(connectedAddress) {
            let res = await skpFarmObj.methods.timeUntilUnlock(connectedAddress).call();

            return res;
        }
    },
    async getSKPAllowance() {
        if(connectedAddress) {
            let res = await skpTokenObj.methods.allowance(connectedAddress, skpFarmAddress).call();

            console.log(res)

            return res / 1e18;
        }
    },
    async getSKPUserStaked() {
        if(connectedAddress) {
            let res = await skpFarmObj.methods.userInfo(connectedAddress).call();

            return res.amount / 1e18;
        }
    },
    async getSKPPending() {
        if(connectedAddress) {
            let res = await skpFarmObj.methods.pendingRewards(connectedAddress).call();

            return res / 1e18;
        }
    },
}

export default Web3Functions;