import { useWeb3 } from '@3rdweb/hooks';
import { useState, useEffect, useRef } from 'react'

import ReadInterface from '../readInterface';
import WriteInterface from '../writeInterface';
import styled from 'styled-components';
import Nav from './Nav';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import ProjectStaking from './ProjectStaking';
import SKPStaking from './StakingPanel/SKPStaking';

interface Props {
    theme: string;
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 5%;
`
const ProjectContainerTitle = styled.h3`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.textMain};
    font-size: 35px;
`
const ProjectStakingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const Main: React.FC<Props> = (props) => {
    const { address, connectWallet, provider, chainId } = useWeb3();
    const [poolObjects, setPoolObjects] = useState(
        [
            {
                poolName: '1 Month',
                address: '0x8e47c677e6A19e034371210442129E40A0A527b1',
                tokenAddress: '0xE1B66A1F5eF08eb246890c3fAB259A2656653E7F',
                tokenPoolAddress: '0x322cA80C4Ed9C2f3D9ECf6Aab2a191934598E3e4',
                data: {
                    apy: 0,
                    withdrawFee: 0,
                    totalStaked: 0,
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                    tokenPrice: 0
                },
                lockupTime: ''
            },
            {
                poolName: '6 Month',
                address: '0x42E97D7a9A61Be0f283d787ee0403adD44692b89',
                tokenAddress: '0xE1B66A1F5eF08eb246890c3fAB259A2656653E7F',
                tokenPoolAddress: '0x322cA80C4Ed9C2f3D9ECf6Aab2a191934598E3e4',
                data: {
                    apy: 0,
                    withdrawFee: 0,
                    totalStaked: 0,
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                    tokenPrice: 0
                },
                lockupTime: ''
            },
            {
                poolName: '1 Year',
                address: '0x5e257A80340F4B2b26f274c44B87f94a52a66175',
                tokenAddress: '0xE1B66A1F5eF08eb246890c3fAB259A2656653E7F',
                tokenPoolAddress: '0x322cA80C4Ed9C2f3D9ECf6Aab2a191934598E3e4',
                data: {
                    apy: 0,
                    withdrawFee: 0,
                    totalStaked: 0,
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                    tokenPrice: 0
                },
                lockupTime: ''
            },
            {
                poolName: 'SNAFU',
                address: '0xfc00413d893af8c19c84c328a0f7144c97e765e5',
                tokenAddress: '0xf30e2cff732a93ceb45f9f9172fd970da88632c6',
                tokenPoolAddress: '0xA37D120132b8576Cd73167E7795716c05491d907',
                data: {
                    apy: 0,
                    withdrawFee: 0,
                    totalStaked: 0,
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                    tokenPrice: 0
                },
                lockupTime: '2 Months'
            },
               {
                poolName: 'NAGI',
                address: '0x2fAA35ff3090C368fCeEaacc31e066c2fb895D65',
                tokenAddress: '0xfEB2D480019bc605a2Ce20903a90Db3f554F1E1c',
                tokenPoolAddress: '0x3984787927a668254C6F22F5c6aDfF14DA80DCF7',
                data: {
                    apy: 0,
                    withdrawFee: 0,
                    totalStaked: 0,
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                    tokenPrice: 0
                },
                lockupTime: '1 Month'
            }
        ]);

    const videoRef = useRef<HTMLVideoElement>(null)
    const setPlayBack = () => {
        videoRef.current.playbackRate = 0.75;
    };

    const setupProvider = async (connector: any) => {
        console.log(connector);
        await connectWallet(connector);
    }

    const updateData = async () => {
        poolObjects.forEach(async (pool) => {
            try {
                let d = await ReadInterface.getData(pool.address, pool.tokenAddress, pool.tokenPoolAddress);

                pool.data = d;
            } catch (err) {
                pool.data =
                {
                    apy: 0,
                    withdrawFee: 0,
                    totalStaked: 0,
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                    tokenPrice: 0,
                }
            }
        })
    }

    const setConnection = async () => {
        if (provider) {
            await WriteInterface.connectWallet(provider?.provider);
            await ReadInterface.setupConnnection(address);
            await updateData();
        }
    }

    useEffect(() => {
        if (address == null) {
            setupProvider('injected');
        }
    }, [])

    useEffect(() => {
        setConnection();
    }, [provider])

    useEffect(() => {
        let interval = setInterval(() => {
            if (provider) {
                updateData();
            } else {

            }
        }, 5000)
        return () => clearInterval(interval);
    })

    const manualConnect = (connector: any) => {
        if (address == null) {
            setupProvider(connector);
        }
    }

    return (
        <Container>
            <Video ref={videoRef} onCanPlay={setPlayBack} playsInline autoPlay muted loop>
                <source src='bg.mp4'/>
            </Video>
            <Nav connect={manualConnect} theme={props.theme} />
            <Header theme={props.theme} />
            <Body poolObjects={poolObjects} theme={props.theme} />
            <ProjectContainerTitle>Project Staking</ProjectContainerTitle>
            <ProjectStakingContainer>
                {
                    poolObjects.map((obj, idx) => {
                        if (idx > 2) {
                            return (<ProjectStaking theme={props.theme} poolObject={obj} />)
                        }
                    })
                }
            </ProjectStakingContainer>
            <ProjectContainerTitle>Vetted</ProjectContainerTitle>
            <ProjectStakingContainer>
                <SKPStaking theme={props.theme}/>
            </ProjectStakingContainer>
            <Footer theme={props.theme} />
        </Container>
    )
}

export default Main;
