import { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import MultiSelector from '../Common/MultiSelector';
import Deposit from './Deposit';
import Button from '../Common/Button';
import SKPUserStake from './SKPUserStake';
import WriteInterface from '../../writeInterface';
import ReadInterface from '../../readInterface';


interface Props {
    theme: string;
}

const Container = styled.div`
    width: 500px;
    height: auto;
    background: ${({ theme }) => theme.bodyLighter};
    margin-bottom: 30px;
    display: flex;
    border: 1px solid ${({ theme }) => theme.bodyLightest};
    -webkit-box-shadow: 5px 5px 29px 10px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 29px 10px rgba(0,0,0,0.1);
    border-radius: 7.5px;
    @media only screen and (max-width: 900px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 550px) {
        width: 90%;
    }
`
const SelectorContainer = styled.div`
    width: 100%;
`
const HeaderContainer = styled.div`
    min-width: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.bodyLightest};
    color: ${({ theme }) => theme.textMain};
    text-size: 12.5px;
    font-weight: bold;
    padding: 7.5px;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    margin-bottom: 10px;
`
const APYContainer = styled.div`
    width: 90%;
    margin-top: -10px;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
`
const InfoContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
`
const APYHeading = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    min-width: 100%;
`
const APY = styled.h1`
    color: ${({ theme }) => theme.accentColor};
    margin-top: -15px;
`
const InfoDescription = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    width: 70%;
    margin-bottom: -10px;
`
const InfoValue = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    width: 30%;
    text-align: right;
    margin-bottom: -10px;
`
const DepositButton = styled(Button)`
    margin-left: 5%;
    margin-bottom: 10px;
`
const DepositMessage = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    margin-left: 5%;
    font-size: 12.5px;
`

const SKPStaking: FC<Props> = (props) => {
    const [lockupTime, setLockupTime] = useState('1 Month')
    const [activePool, setActivePool] = useState(0);
    const [amount, setAmount] = useState('0');
    const [data, setData] = useState({
        tokenBalance: 0,
        allowance: 0,
        userStaked: 0,
        pendingRewards: 0,
        unlockTime: 0,
    })

    const updateLockUp = (time: string) => {
        setLockupTime(time);
    }


    const handleDeposit = async () => {
        if (data.allowance <= data.tokenBalance) {
            await WriteInterface.approveSKP();
        } else {
            await WriteInterface.depositSKP(parseFloat(amount))
        }
    }

    const updateData = async () => {
        try {
            let userStaked = await ReadInterface.getSKPUserStaked();
            let unlockTime = await ReadInterface.getSKPUnlock();
            let allowance = await ReadInterface.getSKPAllowance();
            let tokenBalance = await ReadInterface.getSKPBalance();
            let pendingRewards = await ReadInterface.getSKPPending();

            console.log(data.unlockTime)

            setData({
                userStaked: userStaked,
                unlockTime: unlockTime,
                allowance: allowance,
                tokenBalance: tokenBalance,
                pendingRewards: pendingRewards,
            })

        } catch (err) {
            setData(
                {
                    userStaked: 0,
                    unlockTime: 0,
                    allowance: 0,
                    tokenBalance: 0,
                    pendingRewards: 0,
                })
        }
    }

    useEffect(() => {
        let interval = setInterval(() => {
            updateData();
        }, 500)
        return () => clearInterval(interval);
    })


    return (
        <Container>
            <SelectorContainer>
                <HeaderContainer>SKP LP Staking</HeaderContainer>
                <APYContainer>
                    <APYHeading>APY</APYHeading>
                    <APY>%</APY>
                </APYContainer>
                <Deposit project projectName='SKP LP' updateAmount={setAmount} tokenBalance={data.tokenBalance} theme={props.theme} />
                <InfoContainer>
                    <InfoDescription>Lockup Time:</InfoDescription>
                    <InfoValue>{lockupTime}</InfoValue>
                </InfoContainer>
                {data.allowance <= data.tokenBalance ? <DepositMessage >Approve tokens for staking contract:</DepositMessage> : ''}
                <DepositButton onClick={handleDeposit} width='90%' height='30px' primary theme={props.theme} text={data.allowance <= data.tokenBalance ? 'Approve' : 'Deposit'} />
                <SKPUserStake data={data} theme={props.theme} />
            </SelectorContainer>
        </Container>
    )
}

export default SKPStaking;