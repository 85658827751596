import React from 'react';
import styled from 'styled-components';
import Button from './Common/Button';
import axios from 'axios';
import qs from 'qs';
import { useWeb3 } from '@3rdweb/hooks';


interface Props {
    theme: string;
    valid?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    open?: boolean;
    toggleModal?: (event: React.MouseEvent<SVGTextElement>) => void;
    className?: string;
    selected?: boolean;
    poolName: string;
}

//#region STYLES
const Container = styled.div<Pick<Props, 'open'>>`
    max-width: 400px;
    background: ${({ theme }) => theme.bodyLighter};
    position: absolute;
    z-index: 10000000;
    margin-top: -250px;
    border: 1px solid ${({ theme }) => theme.bodyLightest};
    -webkit-box-shadow: 5px 5px 5000px 5000px rgba(0,0,0,0.23); 
    box-shadow: 5px 5px 5000px 5000px ${({ theme }) => theme.modalShadow};
    -webkit-transition: 0.5s;
    transition: all 0.3s linear;
    
    @media only screen and (max-width: 550px) {
        max-width: 95%;
        margin-left: -7.5%;
    }
`
const HeaderContainer = styled.div`
    min-width: 100%;
    height: 30px;
    text-align: center;
    background-color: ${({ theme }) => theme.bodyLightest};
    color: ${({ theme }) => theme.textMain};
    text-size: 12.5px;
    font-weight: bold;
    padding: 7.5px;
    margin-bottom: 10px;
`
const InnerContainer = styled.div`
    margin-left: 5%;
    width: 90%;
    height: 70%;
    display: flex;
    flex-wrap: wrap;
`
const EmailInnerContainer = styled.div<Pick<Props, 'valid'>>`
    background-color: ${({ theme }) => theme.body};
    border-radius: ${({ theme }) => theme.borderRounding};
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;

    border: 2px solid ${({ theme, valid }) => !valid ? theme.buttonSecondary : theme.accentColor};

`
const EmailInput = styled.input`
    border: hidden;
    background-color: transparent;
    color: ${({ theme }) => theme.textMain};
    width: 60%;
    height: 30px;
    outline: none;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
`
const Label = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    width: 100%;
    margin-bottom: 5px;
    font-size: 12.5px;
`
const SubmitButton = styled(Button)`
    margin-top: 5px;
`
const GuideHeading = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 15px;
    text-align: left;
`
const Content = styled.p`
    font-size: 12.5px;
    color: ${({ theme }) => theme.textAlt};
    margin-top: -10px;
`
const Disclaimer = styled.h3`
    font-size: 10px;
    color: ${({ theme }) => theme.textAlt};
`
const EmailWarning = styled.h3<Pick<Props, 'valid'>>`
    width: 100%;
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    margin-top: -5px;
    margin-left: 10px;

    ${({ valid }) => valid && `
        display: none;
    `}
`
const Success = styled.h3`
    font-size: 12.5px;
    color: ${({ theme }) => theme.textAlt};
    width: 100%;
    text-align: right:
    margin-right: 5px;
`
const Highlight = styled.a`
    color: ${({ theme }) => theme.textMain};
`
const CountryOuterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 5px;
    margin-bottom: -5px;
`
const CountryInnerContainer = styled.div<Pick<Props, 'selected'>>`
    display: flex;
    min-height: 30px;
    justify-content: center;
    border-radius: 7.5px;
    background-color: ${props => props.theme.body};
    border 2px solid transparent;

    ${({ selected }) => selected && `
        border: 2px solid #7049a6;
    `}
`
const CountryFlag = styled.h3`
    font-size: 20px;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
`
//#endregion

const endpointAddress = 'https://api.mindtoken.app:4000';
const preVerificationContent = 'Enter your email and country below and within 24 - 48 hours you will recieve an onboarding email from LifeWorks. If your country is not listed please await further information as we begin to add more availability!'
const postVerificationContent = 'Congratulations you have sucessefully staked with MiND and begun the onboarding process to LifeWorks. Within 24-28 hours you can expect to recieve an email from LifeWorks with final onboarding instructions. We hope you enjoy the services!'

const EmailModal: React.FC<Props> = (props) => {
    const [validEmail, setValidEmail] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailValidated, setEmailValidated] = React.useState(false);
    const [country, setCountry] = React.useState('');
    const [projectName, setProjectName] = React.useState('');
    const { address, connectWallet, provider, chainId } = useWeb3();

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const req = {
            data: qs.stringify({ email: email, address: address, country: country, project: projectName }),
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        };

        if (email && validEmail) {

            let data = {
                email: email,
                address: address,
                country: country,
                project: projectName,
            }

            try {
                console.log('Posting: ' + data);
                axios({
                    method: 'post',
                    url: 'https://api.mindtoken.app:4000/accounts',
                    data: JSON.stringify(data),
                    withCredentials: false,
                    headers: {
                        "Accept": 'application/json',
                        "Content-Type": "application/json",
                    }
                })
                    .then(res => {
                        const data = { res };
                        console.log(data.res.data)
                        if (data.res.data.status === 'success' && data.res.data.onboarded) {
                            console.log('valid')
                            setEmailValidated(true);
                        }
                    }).catch(error => {
                        console.log(error.repsonse.data);
                    })
            } catch (err) {
                console.log(err);
            }
        }
    }

    React.useEffect(() => {
        axios.get(`${endpointAddress}/accounts`, {
            headers: {
                'Content-Type': "application/json"
            },
            params: {
                address: address
            }
        }).then(res => {
            const data = { res }
            if (data.res.data.onboarded === true) {
                setEmailValidated(true);

            } else {
                setEmailValidated(false);
            }
        })
    }, [address]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(props.poolName == undefined) {
            setProjectName('Mind')
        } else {
            setProjectName(props.poolName);
        }

        const query = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (query.test(event.target.value)) {
            setEmail(event.target.value);
            setValidEmail(true);
        } else {
            setEmail(event.target.value);
            setValidEmail(false);
        }
    }

    return (
        <Container className={props.className}>
            <HeaderContainer>
                Submit Email
                <text onClick={props.toggleModal} style={{ float: 'right', marginRight: '2px' }}>X</text>
            </HeaderContainer>
            <InnerContainer>
                <GuideHeading>What's next?</GuideHeading>
                <Content>{emailValidated ? postVerificationContent : preVerificationContent}</Content>
                {
                    !emailValidated ?
                        <>
                            <Label>Country</Label>
                            <CountryOuterContainer>
                                <CountryInnerContainer selected={country == 'Canada' ? true : false}>
                                    <CountryFlag onClick={() => setCountry('Canada')}><span role="img" aria-label="Canada">🇨🇦</span></CountryFlag>
                                </CountryInnerContainer>
                                <CountryInnerContainer selected={country == 'United Kingdom' ? true : false}>
                                    <CountryFlag onClick={() => setCountry('United Kingdom')}><span role="img" aria-label="united-kingdom">🇬🇧</span></CountryFlag>
                                </CountryInnerContainer>
                                <CountryInnerContainer selected={country == 'United States' ? true : false}>
                                    <CountryFlag onClick={() => setCountry('United States')}><span role="img" aria-label="united-states">🇺🇸</span></CountryFlag>
                                </CountryInnerContainer>
                            </CountryOuterContainer>
                            <Label>Email</Label>
                            <EmailInnerContainer valid={validEmail}>
                                <EmailInput onChange={handleChange} placeholder='youremailhere@email.com' />
                                <SubmitButton onClick={handleSubmit} disabled={(validEmail && country != '') ? false : true} theme={props.theme} width='35%' primary height='30px' text='Submit' />
                                <EmailWarning valid={validEmail}>*Enter a valid email</EmailWarning>
                            </EmailInnerContainer>
                            <Disclaimer>* MiND collects and stores Email/Wallet addresses for purpose of tracking onboarding onto LifeWorks.</Disclaimer>
                        </>
                        :
                        <Success>If you need support contact <Highlight>info@mindtoken.app</Highlight> or contact us on <Highlight>TeleGram.</Highlight></Success>
                }
            </InnerContainer>
        </Container>
    )
}

export default EmailModal;
