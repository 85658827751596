import styled from 'styled-components';
import React from 'react';
import Button from '../Common/Button';

interface Props {
    theme: string;
    tokenBalance: number;
    updateAmount: React.Dispatch<React.SetStateAction<string>>;
    project?: boolean;
    projectName?: string;
}

const DepositContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-bottom: 10px;
`
const DepositInnerContainer = styled.span`
    background-color: ${({ theme }) => theme.body};
    border-radius: ${({ theme }) => theme.borderRounding};
    min-width: 100%;
    display: flex;
`
const DepositHeading = styled.h3`
    width: 30%;
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
`
const DepositBalance = styled.h3`
    width: 70%;
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    text-align: right;
`
const DepositInput = styled.input`
    border: hidden;
    background-color: transparent;
    color: ${({ theme }) => theme.textMain};
    width: 70%;
    margin: 10px;
    outline: none;
`
const DepositLabel = styled.h3`
    width: 30%;
    text-align: right;
    font-size: 12.5px;
    margin-right: 10px;
    color: ${({ theme }) => theme.textAlt};
`
const ButtonContainer = styled.div`
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
`

const Deposit: React.FC<Props> = (props) => {
    const [depositAmount, setDepositAmount] = React.useState('0')

    const updateValues = (amount: number, divisor: number) => {
        if (amount && amount > 0) {
            setDepositAmount((amount * divisor).toString());
            props.updateAmount((amount * divisor).toString());
        }
    }

    const handleChange = (e) => {
        setDepositAmount(e.target.value);
        props.updateAmount(e.target.value);
    }

    return (
        <DepositContainer>
            <DepositHeading>{`Deposit ` + (props.project ? props.projectName : 'MiND')}</DepositHeading>
            <DepositBalance>{`${props.tokenBalance} ` + (props.project ? props.projectName : 'MiND')}</DepositBalance>
            <DepositInnerContainer>
                <DepositInput value={depositAmount} onChange={(e) => handleChange(e)} placeholder='0.0' />
                <DepositLabel>{props.project ? props.projectName : 'MiND'}</DepositLabel>
            </DepositInnerContainer>
            <ButtonContainer>
                <Button onClick={() => updateValues(props.tokenBalance, 0.25)} primary theme={props.theme} text='25%' width='20%' height='100%' />
                <Button onClick={() => updateValues(props.tokenBalance, 0.5)} primary theme={props.theme} text='50%' width='20%' height='100%' />
                <Button onClick={() => updateValues(props.tokenBalance, 0.75)} primary theme={props.theme} text='75%' width='20%' height='100%' />
                <Button onClick={() => updateValues(props.tokenBalance, 1)} primary theme={props.theme} text='100%' width='20%' height='100%' />
            </ButtonContainer>
        </DepositContainer>
    )
}

export default Deposit;