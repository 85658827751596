import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MultiSelector from './Common/MultiSelector';
import Deposit from './StakingPanel/Deposit';
import Button from './Common/Button';
import UserStake from './StakingPanel/UserStake';
import WriteInterface from '../writeInterface';


interface Props {
    theme: string;
    lockupTimer?: string;
    poolObject: {
        data: {
            apy: number
            tokenBalance: number,
            allowance: number,
            userStaked: number,
            pendingRewards: number,
            unlockTime: number,
            tokenPrice: number,
            withdrawFee: number
        }
        address: string,
        poolName: string,
        lockupTime: string,
        tokenAddress: string;
    };
}

const Container = styled.div`
    width: 500px;
    height: auto;
    background: ${({ theme }) => theme.bodyLighter};
    margin-bottom: 30px;
    display: flex;
    border: 1px solid ${({ theme }) => theme.bodyLightest};
    -webkit-box-shadow: 5px 5px 29px 10px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 29px 10px rgba(0,0,0,0.1);
    border-radius: 7.5px;
    @media only screen and (max-width: 900px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 550px) {
        width: 90%;
    }
`
const SelectorContainer = styled.div`
    width: 100%;
`
const HeaderContainer = styled.div`
    min-width: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.bodyLightest};
    color: ${({ theme }) => theme.textMain};
    text-size: 12.5px;
    font-weight: bold;
    padding: 7.5px;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    margin-bottom: 10px;
`
const APYContainer = styled.div`
    width: 90%;
    margin-top: -10px;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
`
const InfoContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
`
const APYHeading = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    min-width: 100%;
`
const APY = styled.h1`
    color: ${({ theme }) => theme.accentColor};
    margin-top: -15px;
`
const InfoDescription = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    width: 70%;
    margin-bottom: -10px;
`
const InfoValue = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    width: 30%;
    text-align: right;
    margin-bottom: -10px;
`
const DepositButton = styled(Button)`
    margin-left: 5%;
    margin-bottom: 10px;
`
const DepositMessage = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    margin-left: 5%;
    font-size: 12.5px;
`

const StakingPanel: React.FC<Props> = (props) => {
    const [amount, setAmount] = useState('0');
    const [data, setData] = useState({
        apy: 25,
        tokenBalance: 0,
        allowance: 0,
        userStaked: 0,
        pendingRewards: 0,
        unlockTime: 0,
        poolAddress: '',
        withdrawFee: 0,
        tokenPrice: 0
    })


    const handleDeposit = async () => {
        if (data.allowance <= data.tokenBalance) {
            await WriteInterface.approve(props.poolObject.address, props.poolObject.tokenAddress);
        } else {
            await WriteInterface.deposit(props.poolObject.address, parseFloat(amount))
        }
    }

    useEffect(() => {
        let interval = setInterval(() => {
            try {
                setData({
                    apy: props.poolObject.data.apy,
                    tokenBalance: props.poolObject.data.tokenBalance,
                    allowance: props.poolObject.data.allowance,
                    userStaked: props.poolObject.data.userStaked,
                    pendingRewards: props.poolObject.data.pendingRewards,
                    unlockTime: props.poolObject.data.unlockTime,
                    poolAddress: props.poolObject.address,
                    withdrawFee: props.poolObject.data.withdrawFee,
                    tokenPrice: props.poolObject.data.tokenPrice
                })
            } catch (err) {
                setData({
                    apy: 25,
                    tokenBalance: 0,
                    allowance: 0,
                    userStaked: 0,
                    pendingRewards: 0,
                    unlockTime: 0,
                    poolAddress: '',
                    withdrawFee: 0,
                    tokenPrice: 0
                })
            }
        }, 10)
        return () => clearInterval(interval);
    })

    return (
        <Container>
            <SelectorContainer>
                <HeaderContainer>{props.poolObject.poolName}</HeaderContainer>
                <APYContainer>
                    <APYHeading>APY</APYHeading>
                    <APY>{data.apy}%</APY>
                </APYContainer>
                <Deposit project projectName={props.poolObject.poolName} updateAmount={setAmount} tokenBalance={data.tokenBalance} theme={props.theme} />
                <InfoContainer>
                    <InfoDescription>Lockup Time:</InfoDescription>
                    <InfoValue>{props.poolObject.lockupTime}</InfoValue>
                </InfoContainer>
                {data.allowance <= data.tokenBalance ? <DepositMessage >Approve tokens for staking contract:</DepositMessage> : ''}
                <DepositButton onClick={handleDeposit} width='90%' height='30px' primary theme={props.theme} text={data.allowance <= data.tokenBalance ? 'Approve' : 'Deposit'} />
                <UserStake project projectName={props.poolObject.poolName} data={data} theme={props.theme} />
            </SelectorContainer>
        </Container>
    )
}

export default StakingPanel;