import './App.css';
import { defaultTheme, lightTheme, darkTheme } from './Components/Styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './Components/Styles/globalStyles';
import { ThirdwebWeb3Provider } from '@3rdweb/hooks';
import Main from './Components/Main'
import React from 'react';
import {
  BrowserRouter as Router, 
  Route,
  Routes
} from 'react-router-dom'

interface Props {
  theme: string;
}

function App() {
  const [theme, setTheme] = React.useState('default');

  const supportedChainIds = [1, 4, 97, 56];

  const connectors = {
    injected: {},
    walletconnect: {}
  }

  return (
    <ThirdwebWeb3Provider supportedChainIds={supportedChainIds} connectors={connectors}>
      <ThemeProvider theme={darkTheme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path='/' element={<Main theme={theme}/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
    </ThirdwebWeb3Provider>
  );
}

export type ThemeType = typeof defaultTheme;
export default App;
