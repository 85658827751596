import { contractAbi, stakingAbi, skpAbi } from './ABI/ABI';
const Web3 = require('web3')

let connectedAddress: string;
let web3;
let ethereum: any;

//MAINNET
const contractAddress = '0xE1B66A1F5eF08eb246890c3fAB259A2656653E7F';
const skpFarmAddress = '0xdE492852E4F5440d5538A4DB43317837989940E0';
const skpTokenAddress = '0xE0Be422249882F2236Dc17599D279e1F8337556E';

let contractObj: any;
let skpFarmObj;
let skpTokenObj;

const Web3Functions = {

    async connectWallet(eth: any) {
        ethereum = eth;
        try {
            connectedAddress = await ethereum.request({ method: 'eth_accounts' });
            connectedAddress = connectedAddress[0];

            web3 = new Web3(ethereum);
            web3.eth.defaultAccount = web3.eth.accounts[0];

            contractObj = new web3.eth.Contract(JSON.parse(contractAbi), contractAddress);
            skpFarmObj = new web3.eth.Contract(JSON.parse(skpAbi), skpFarmAddress);
            skpTokenObj = new web3.eth.Contract(JSON.parse(contractAbi), skpTokenAddress);

        } catch (err) {
            console.log(err);
        }
    },
    async approve(poolAddress: string, tokenAddress) {
        try {
            const tokenObj = new web3.eth.Contract(JSON.parse(contractAbi), tokenAddress);

            const callData = tokenObj.methods.approve(poolAddress, '115792089237316195423570985008687907853269984665640564039457584007913129639935').encodeABI();
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: tokenAddress,
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async deposit(poolAddress: string, amount: number) {
        const depAmount = (amount * 1e18).toLocaleString('fullwide', { useGrouping: false });
        console.log(amount);

        let poolObj = new web3.eth.Contract(JSON.parse(stakingAbi), poolAddress);

        const callData = poolObj.methods.deposit(depAmount).encodeABI();
        try {
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: poolAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async withdraw(poolAddress: string) {
        let poolObj = new web3.eth.Contract(JSON.parse(stakingAbi), poolAddress);

        const callData = poolObj.methods.withdraw().encodeABI();
        try {
            console.log('Withdrawing...')
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: poolAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async earlyWithdraw(poolAddress: string, penalty: number) {
        let poolObj = new web3.eth.Contract(JSON.parse(stakingAbi), poolAddress);
        let unstakeValue = web3.utils.toHex(web3.utils.toWei(penalty.toString(), "ether"))

        const callData = poolObj.methods.emergencyWithdraw().encodeABI();
        try {
            console.log('Emergency Withdrawing (Penalty Applied)...')
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: poolAddress,
                    value: unstakeValue
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                console.log(err);
            })
        } catch (err) {
            console.log(err);
        }
    },
    async claim(poolAddress: string) {
        let poolObj = new web3.eth.Contract(JSON.parse(stakingAbi), poolAddress);

        const callData = poolObj.methods.deposit('0').encodeABI();
        try {
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: poolAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async compound(poolAddress: string) {
        let poolObj = new web3.eth.Contract(JSON.parse(stakingAbi), poolAddress);

        const callData = poolObj.methods.compound().encodeABI();
        try {
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: poolAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async approveSKP() {
        if(connectedAddress) {
            try {
                const callData = skpTokenObj.methods.approve(skpFarmAddress, '115792089237316195423570985008687907853269984665640564039457584007913129639935').encodeABI();
                await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        data: callData,
                        from: connectedAddress,
                        to: skpTokenAddress,
                    }]
                }).then((txHash: string) => {
                    return txHash;
                }).catch((err: any) => {
                    return err;
                })
            } catch (err) {
                console.log(err);
            }
        }

    },
    async depositSKP(amount) {
        const depAmount = (amount * 1e18).toLocaleString('fullwide', { useGrouping: false });

        const callData = skpFarmObj.methods.stake(depAmount).encodeABI();
        try {
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: skpFarmAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async withdrawSKP(amount) {
        const depAmount = (amount * 1e18).toLocaleString('fullwide', { useGrouping: false });

        const callData = skpFarmObj.methods.withdraw('depAmount').encodeABI();
        try {
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: skpFarmAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    },
    async claimSKP() {

        const callData = skpFarmObj.methods.claimRewards().encodeABI();
        try {
            await ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    data: callData,
                    from: connectedAddress,
                    to: skpFarmAddress
                }]
            }).then((txHash: string) => {
                return txHash;
            }).catch((err: any) => {
                return err;
            })
        } catch (err) {
            console.log(err);
        }
    }
}

export default Web3Functions;