import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../Common/Button";
import WriteInterface from "../../writeInterface";
import EmailModal from "../EmailModal";

interface Props {
  theme: string;
  data: {
    userStaked: number;
    pendingRewards: number;
    unlockTime: number;
    poolAddress: string;
    withdrawFee: number;
    tokenPrice: number;
  };
  open?: boolean;
  project?: boolean;
  projectName?: string;
}

const Container = styled.div`
  width: 90%;
  margin-top: 10px;
  margin-left: 5%;
  display: flex;
  flex-wrap: wrap;
`;
const Divider = styled.div`
  min-width: 100%;
  min-height: 2.5px;
  background-color: ${({ theme }) => theme.bodyLightest};
`;
const Header = styled.h3`
  min-width: 100%;
  color: ${({ theme }) => theme.textMain};
  font-size: 15px;
  text-align: center;
`;
const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: -15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const DetailsHeader = styled.h3`
  width: 60%;
  color: ${({ theme }) => theme.textAlt};
  font-size: 12.5px;
  margin-bottom: -10px;
`;
const DetailsValue = styled.h3`
  width: 40%;
  color: ${({ theme }) => theme.textMain};
  font-size: 12.5px;
  text-align: right;
  margin-bottom: -10px;
`;
const ClaimRewardsButton = styled(Button)`
  margin-top: 20px;
`;
const EarlyWithdrawButton = styled(ClaimRewardsButton)`
  margin-left: 4%;
`;
const WithdrawWarning = styled.h3`
  color: ${({ theme }) => theme.textAlt};
  font-size: 12.5px;
  margin-bottom: -10px;
`;
const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.textMain};
`;
const OnboardButton = styled(Button)`
  margin-top: 10px;
`;
const EmailModalStaking = styled(EmailModal)<Pick<Props, "open">>`
  display: ${(props) => (props.open ? "block" : "none")};
`;

const UserStake: React.FC<Props> = (props) => {
  const [canClaim, setCanClaim] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [claimTime, setClaimTime] = useState({
    days: 30,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    console.log(modalOpen);
  };

  const getCountdown = (deadline) => {
    const now = Date.now() / 1000;
    const total = deadline - now;
    const _seconds = Math.floor(total % 60);
    const _minutes = Math.floor((total / 60) % 60);
    const _hours = Math.floor((total / (60 * 60)) % 24);
    const _days = Math.floor(total / (60 * 60 * 24));

    setClaimTime({
      days: _days > 0 ? _days : 0,
      hours: _hours > 0 ? _hours : 0,
      minutes: _minutes > 0 ? _minutes : 0,
      seconds: _seconds > 0 ? _seconds : 0,
    });

    if (claimTime.days < 1 && claimTime.hours < 1 && claimTime.minutes < 1 && claimTime.seconds < 1) {
      setCanClaim(true);
    } else {
      setCanClaim(false);
    }
  };

  const handleWithdraw = async () => {
    if (canClaim) {
      await WriteInterface.withdraw(props.data.poolAddress);
    } else {
      await WriteInterface.earlyWithdraw(
        props.data.poolAddress,
        props.data.withdrawFee != undefined ? parseFloat(props.data.withdrawFee.toFixed(4)) : 0
      );
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (props.data.unlockTime > Date.now() / 1000) {
        getCountdown(props.data.unlockTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [claimTime, props.data.unlockTime]);

  return (
    <Container>
      <EmailModalStaking poolName={props.projectName} open={modalOpen} toggleModal={toggleModal} theme={props.theme} />
      <Divider />
      <Header>Your Staking</Header>
      <DetailsContainer>
        <DetailsHeader>{props.project ? `Staked ${props.projectName}:` : "Staked MiND:"}</DetailsHeader>
        <DetailsValue>
          {`${props.data.userStaked != undefined ? props.data.userStaked.toFixed(2) : 0}` +
            (props.project
              ? ` ${props.projectName} `
              : " MiND " + `($${props.data.userStaked != undefined ? (props.data.userStaked * props.data.tokenPrice).toFixed(2) : 0}) `)}
        </DetailsValue>
        <DetailsHeader>Time to Unlock:</DetailsHeader>
        <DetailsValue>{`${claimTime.days}d ${claimTime.hours}hr ${claimTime.minutes}m ${claimTime.seconds}s`}</DetailsValue>
        <DetailsHeader>Pending Rewards:</DetailsHeader>
        <DetailsValue>
          {`${props.data.pendingRewards != undefined ? props.data.pendingRewards.toFixed(4) : 0}` +
            (props.project ? ` ${props.projectName} ` : " MiND ") +
            `($${props.data.pendingRewards != undefined ? (props.data.pendingRewards * props.data.tokenPrice).toFixed(2) : 0})`}
        </DetailsValue>
        <DetailsHeader>Early Withdraw Fee:</DetailsHeader>
        <DetailsValue>{`${props.data.withdrawFee != undefined ? props.data.withdrawFee.toFixed(4) : 0} BNB`}</DetailsValue>
        <ClaimRewardsButton
          onClick={async () => await WriteInterface.claim(props.data.poolAddress)}
          width="58%"
          height="30px"
          primary
          theme={props.theme}
          text="Claim Rewards"
        />
        <EarlyWithdrawButton
          onClick={async () => await handleWithdraw()}
          width="38%"
          height="30px"
          primary={canClaim ? true : false}
          secondary={canClaim ? false : true}
          theme={props.theme}
          text={canClaim ? "Withdraw" : "Early Withdraw"}
        />
        {props.data.userStaked > 0 ? <OnboardButton onClick={toggleModal} primary width="100%" height="30px" text="Onboard To LifeWorks" /> : ""}
        <WithdrawWarning>
          * Early withdraw is subject to a decaying fee. See <Link href="https://mindtoken.app/images/MiND-Whitepaper-Final.pdf">WhitePaper</Link> for
          more details
        </WithdrawWarning>
      </DetailsContainer>
    </Container>
  );
};

export default UserStake;
