import React from 'react';
import styled from 'styled-components';
import StakingPanel from './StakingPanel/StakingPanel';
import StakingInfo from './StakingInfo';
import { useEffect, useState } from 'react';

interface props {
    theme: string;
    poolObjects: {}[]
}

const Container = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-top: 20px;
`
const InnerContainerL = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 900px) {
        width: 95%;
        margin-left: 5%;
    }
`
const InnerContainerR = styled.div`
    width: 60%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 900px) {
        width: 100%;
    }
`

const Body:React.FC<props> = ( props ) => {
    const [mindStakingPools, setMindStakingPools] = useState([]);

    useEffect(() => {
        let interval = setInterval(() => {         
            mindStakingPools[0] = props.poolObjects[0];
            mindStakingPools[1] = props.poolObjects[1];
            mindStakingPools[2] = props.poolObjects[2];

        }, 1000)
        return () => clearInterval(interval);
    })

    return (
        <Container>
            <InnerContainerL>
                <StakingInfo theme={props.theme}/>
            </InnerContainerL>
            <InnerContainerR>
                <StakingPanel poolObject={mindStakingPools} theme={props.theme}/>
            </InnerContainerR>
        </Container>
    )
}

export default Body;