import React from 'react';
import styled from 'styled-components';

interface Props { 
    theme: string;
}

const Container = styled.div`
    width: 500px;
    height: auto;
    margin-left: 30%;
    margin-top: 100px;

    @media only screen and (max-width: 1000px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0px;
        margin-top: 0px;
    }
`
const HeaderContainer = styled.div`
    width: 100%;
    text-align: left;
`
const Header = styled.h3`
    color: ${({ theme }) => theme.textMain};
    height: auto;
    font-size: 20px;
    margin-top: 0px;
    min-width: 100%;
`
const InfoSummary = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.textAlt};
    text-align: left;
    margin-left: 10px;
`
const BenefitsHeader = styled.h3`
    color: ${({ theme }) => theme.textMain};
    min-width: 100%;
    font-size: 15px;
`
const Disclaimer = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    margin-bottom: 30px;
    margin-left: 10px;
    font-size: 12.5px;
`

const StakingInfo:React.FC<Props> = ( props ) => {

    return (
        <Container>
            <HeaderContainer>
                <Header>Why stake with MiND?</Header>
            </HeaderContainer>
            <InfoSummary>
            MiND token staking offers great APYs and grants free access to the Lifeworks app! 
            Lifeworks is not only for the individual user, but also their immediate family! 
            </InfoSummary>
            <HeaderContainer>
                <BenefitsHeader>Some benefits of staking with MiND:</BenefitsHeader>
            </HeaderContainer>
            <InfoSummary>
                • Legal consultations <br/>
                • Video and text counselling sessions <br/>
                • Telemedicine consultations <br/>
                • Access to the fitness app Lift <br/>
                • Life coaches <br/>
                • Perks <br/>
                • Purchase discounts from large brand companies <br/>
            </InfoSummary>
            <Disclaimer>
                *Some services have limitations per annum. <br/>
                *This service is currently available in Canada, the United States and the United Kingdom. MiND will begin unlocking more regions post launch!
            </Disclaimer>
        </Container>
    )
}

export default StakingInfo;