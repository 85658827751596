import React from 'react';
import styled from 'styled-components';

interface props {
    theme: string;
}

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.textMain}
`
const IntroHeader = styled.h1`
    min-width: 100%;
    height: auto;
    font-size: 35px;
    margin-bottom: -5px;
`
const IntroDescription = styled.h4`
    width: 100%;
    color: ${({ theme }) => theme.textAlt};
`

const Header:React.FC<props> = (props) => {

    return (
        <Container>
            <IntroHeader>MiND Token Staking</IntroHeader>
            <IntroDescription>Stake MiND and Receive Real Life Mental + Health Support</IntroDescription>
        </Container>
    )
}

export default Header;

