import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MultiSelector from '../Common/MultiSelector';
import Deposit from './Deposit';
import Button from '../Common/Button';
import UserStake from './UserStake';
import WriteInterface from '../../writeInterface';


interface Props {
    theme: string;
    lockupTimer?: string;
    poolObject: {};
}

const Container = styled.div`
    width: 500px;
    height: auto;
    background: ${({ theme }) => theme.bodyLighter};
    margin-bottom: 30px;
    display: flex;
    border: 1px solid ${({ theme }) => theme.bodyLightest};
    -webkit-box-shadow: 5px 5px 29px 10px rgba(0,0,0,0.1); 
    box-shadow: 5px 5px 29px 10px rgba(0,0,0,0.1);
    border-radius: 7.5px;
    @media only screen and (max-width: 900px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 550px) {
        width: 90%;
    }
`
const SelectorContainer = styled.div`
    width: 100%;
`
const HeaderContainer = styled.div`
    min-width: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.bodyLightest};
    color: ${({ theme }) => theme.textMain};
    text-size: 12.5px;
    font-weight: bold;
    padding: 7.5px;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
    margin-bottom: 10px;
`
const APYContainer = styled.div`
    width: 90%;
    margin-top: -10px;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
`
const InfoContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
`
const APYHeading = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    min-width: 100%;
`
const APY = styled.h1`
    color: ${({ theme }) => theme.accentColor};
    margin-top: -15px;
`
const InfoDescription = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    width: 70%;
    margin-bottom: -10px;
`
const InfoValue = styled.h3`
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    width: 30%;
    text-align: right;
    margin-bottom: -10px;
`
const DepositButton = styled(Button)`
    margin-left: 5%;
    margin-bottom: 10px;
`
const DepositMessage = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    margin-left: 5%;
    font-size: 12.5px;
`

const StakingPanel: React.FC<Props> = (props) => {
    const [lockupTime, setLockupTime] = useState('1 Month')
    const [activePool, setActivePool] = useState(0);
    const [amount, setAmount] = useState('0');
    const [data, setData] = useState({
        apy: 25,
        tokenBalance: 0,
        allowance: 0,
        userStaked: 0,
        pendingRewards: 0,
        unlockTime: 0,
        poolAddress: '',
        withdrawFee: 0,
        tokenPrice: 0
    })

    const updateLockUp = (time: string) => {
        setLockupTime(time);
    }


    const handleDeposit = async () => {
        if (data.allowance <= data.tokenBalance) {
            await WriteInterface.approve(props.poolObject[activePool].address, props.poolObject[activePool].tokenAddress);
        } else {
            await WriteInterface.deposit(props.poolObject[activePool].address, parseFloat(amount))
        }
    }

    useEffect(() => {
        let interval = setInterval(() => {
            switch (lockupTime) {
                case '1 Month':
                    try {
                        setActivePool(0);
                        setData({
                            apy: props.poolObject[0].data.apy,
                            tokenBalance: props.poolObject[0].data.tokenBalance,
                            allowance: props.poolObject[0].data.allowance,
                            userStaked: props.poolObject[0].data.userStaked,
                            pendingRewards: props.poolObject[0].data.pendingRewards,
                            unlockTime: props.poolObject[0].data.unlockTime,
                            poolAddress: props.poolObject[0].address,
                            withdrawFee: props.poolObject[0].data.withdrawFee,
                            tokenPrice: props.poolObject[0].data.tokenPrice
                        })
                    } catch (err) {
                    }
                    break;
                case '6 Month':
                    try {
                        setActivePool(1);
                        setData({
                            apy: props.poolObject[1].data.apy,
                            tokenBalance: props.poolObject[0].data.tokenBalance,
                            allowance: props.poolObject[1].data.allowance,
                            userStaked: props.poolObject[1].data.userStaked,
                            pendingRewards: props.poolObject[1].data.pendingRewards,
                            unlockTime: props.poolObject[1].data.unlockTime,
                            poolAddress: props.poolObject[1].address,
                            withdrawFee: props.poolObject[1].data.withdrawFee,
                            tokenPrice: props.poolObject[0].data.tokenPrice
                        })
                    } catch (err) {
                    }
                    break;
                case '1 Year':
                    try {
                        setActivePool(2);
                        setData({
                            apy: props.poolObject[2].data.apy,
                            tokenBalance: props.poolObject[0].data.tokenBalance,
                            allowance: props.poolObject[2].data.allowance,
                            userStaked: props.poolObject[2].data.userStaked,
                            pendingRewards: props.poolObject[2].data.pendingRewards,
                            unlockTime: props.poolObject[2].data.unlockTime,
                            poolAddress: props.poolObject[2].address,
                            withdrawFee: props.poolObject[2].data.withdrawFee,
                            tokenPrice: props.poolObject[0].data.tokenPrice,
                        })
                    } catch (err) {
                        
                    }
                    break;
            }
        }, 10)
        return () => clearInterval(interval);
    })

    return (
        <Container>
            <SelectorContainer>
                <HeaderContainer>MiND Staking Pool</HeaderContainer>
                <MultiSelector updateStakeType={setLockupTime} width={'80'} />
                <APYContainer>
                    <APYHeading>APY</APYHeading>
                    <APY>{data.apy}%</APY>
                </APYContainer>
                <Deposit updateAmount={setAmount} tokenBalance={data.tokenBalance} theme={props.theme} />
                <InfoContainer>
                    {/* <InfoDescription>Staking Fee:</InfoDescription> */}
                    {/* <InfoValue>1%</InfoValue> */}
                    <InfoDescription>Lockup Time:</InfoDescription>
                    <InfoValue>{lockupTime}</InfoValue>
                </InfoContainer>
                {data.allowance <= data.tokenBalance ? <DepositMessage >Approve tokens for staking contract:</DepositMessage> : ''}
                <DepositButton onClick={handleDeposit} width='90%' height='30px' primary theme={props.theme} text={data.allowance <= data.tokenBalance ? 'Approve' : 'Deposit'} />
                <UserStake data={data} theme={props.theme} />
            </SelectorContainer>
        </Container>
    )
}

export default StakingPanel;