import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../Common/Button';
import WriteInterface from '../../writeInterface';
import EmailModal from '../EmailModal'


interface Props {
    theme: string;
    data?: {
        tokenBalance: number,
        allowance: number,
        userStaked: number,
        pendingRewards: number,
        unlockTime: number,
    },
    open?: boolean;
    project?: boolean;
    projectName?: string;
}

const Container = styled.div`
    width: 90%;
    margin-top: 10px;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
`
const Divider = styled.div`
    min-width: 100%;
    min-height: 2.5px;
    background-color: ${({ theme }) => theme.bodyLightest}
`
const Header = styled.h3`
    min-width: 100%;
    color: ${({ theme }) => theme.textMain};
    font-size: 15px;
    text-align: center;
`
const DetailsContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: -15px;
    flex-wrap: wrap;
    margin-bottom: 20px;
`
const DetailsHeader = styled.h3`
    width: 60%;
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    margin-bottom: -10px;
`
const DetailsValue = styled.h3`
    width: 40%;
    color: ${({ theme }) => theme.textMain};
    font-size: 12.5px;
    text-align: right;
    margin-bottom: -10px;
`
const ClaimRewardsButton = styled(Button)`
    margin-top: 20px;
`
const EarlyWithdrawButton = styled(ClaimRewardsButton)`
    margin-left: 4%;
`
const WithdrawWarning = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
    margin-bottom: -10px;
`
const Link = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.textMain};
`
const OnboardButton = styled(Button)`
    margin-top: 10px;
`
const EmailModalStaking = styled(EmailModal) <Pick<Props, 'open'>>`
    display: ${props => props.open ? 'block' : 'none'}
`


const UserStake:React.FC<Props> = ( props ) => {
    const [canClaim, setCanClaim] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [claimTime, setClaimTime] = useState({
        days: 30,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        console.log(modalOpen);
    }

    const getCountdown = (deadline) => {
        const now = Date.now() / 1000;
        const total = deadline - now;
        const _seconds = Math.floor((total) % 60);
        const _minutes = Math.floor((total / 60) % 60);
        const _hours = Math.floor((total / (60 * 60)) % 24);
        const _days = Math.floor(total / (60 * 60 * 24));

        setClaimTime({
            days: _days > 0 ? _days : 0,
            hours: _hours > 0 ? _hours: 0,
            minutes: _minutes > 0 ? _minutes: 0,
            seconds: _seconds > 0 ? _seconds: 0
        })

        if(claimTime.days < 1 && claimTime.hours < 1 && claimTime.minutes < 1 && claimTime.seconds < 1) {
            setCanClaim(true);
        } else {
            setCanClaim(false);
        }
    }

    return (
        <Container>
            <EmailModalStaking poolName={'SKP'} open={modalOpen} toggleModal={toggleModal} theme={props.theme} />
            <Divider/> 
            <Header>Your Staking</Header>
            <DetailsContainer>
                <DetailsHeader>Staked SKP LP:</DetailsHeader>
                <DetailsValue>{`${props.data.userStaked != undefined ? props.data.userStaked.toFixed(6) : 0}` + ' SKP LP '}</DetailsValue>
                <DetailsHeader>Withdrawable:</DetailsHeader>
                <DetailsValue>{(props.data.unlockTime == 0) ? 'Yes' : 'No'}</DetailsValue>
                <DetailsHeader>Pending Rewards:</DetailsHeader>
                <DetailsValue>{`${props.data.pendingRewards != undefined ? props.data.pendingRewards.toFixed(4) : 0}` + ' SKP LP '}</DetailsValue>
                <DetailsHeader>Early Withdraw Fee:</DetailsHeader>
                <DetailsValue>{`5%`}</DetailsValue>
                <ClaimRewardsButton onClick={async () => WriteInterface.claimSKP()}  width='58%' height='30px' primary theme={props.theme} text='Claim Rewards'/>
                <EarlyWithdrawButton onClick={async () => WriteInterface.withdrawSKP(props.data.userStaked)} width='38%' height='30px' secondary theme={props.theme} text='Withdraw'/>
                {
                    props.data.userStaked > 0 ? <OnboardButton onClick={toggleModal} primary width='100%' height='30px' text='Onboard To LifeWorks'/> : ''
                }
                /* <WithdrawWarning>* Early withdraw is subject to a decaying fee. See <Link href='https://mindtoken.app/images/MiND-Whitepaper-Final.pdf'>WhitePaper</Link> for more details</WithdrawWarning> */
            </DetailsContainer>
        </Container>
    )
}

export default UserStake;