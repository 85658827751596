import React from 'react';
import styled from 'styled-components';

interface Props {
    theme: string;
}

const Container = styled.div`
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 550px) {
        margin-top: -25px;
    }
`
const InnerContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
    display: flex;
    justify-content: space-around;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
    @media screen and (max-width: 550px) {
       margin-bottom: 20px;
       flex-wrap: wrap;
    }
`
const FooterItem = styled.h3`
    color: ${({ theme }) => theme.textAlt};
    font-size: 12.5px;
`
const Link = styled.a`
    width: 33%;
    text-decoration: none;
    text-align: center;

    @media screen and (max-width: 550px) {
        width: 90%;
        margin-left: 5%;
        text-align: left;
    }
`
const Footer: React.FC<Props> = (props) => {

    return (
        <Container>
            <InnerContainer>
                <Link>
                    <FooterItem>Support: info@mindtoken.app</FooterItem>
                </Link>
                <Link target='_blank' href='https://beta.canadasbusinessregistries.ca/search/results?search=%7BMNTL%7D&status=Active'>
                    <FooterItem>© MNTL services Inc</FooterItem>
                </Link>
                <Link>
                    <FooterItem>All Rights Reserved</FooterItem>
                </Link>
            </InnerContainer>
        </Container>
    )
}

export default Footer;