export const darkTheme = {
    body: '#222B30',
    bodyLighter: '#334048',
    bodyLightest: '#4C5E6B',
    buttonPrimary: '#7049a6',
    buttonSecondary: '#7A0000',
    buttonDisabled: '#828282',
    modalShadow: 'rgba(79,79,79,0.50)',
    themeToggle: '#FFFFFF',
    textMain: '#F2F6F8',
    textAlt: '#6A7982',
    accentColor: '#7049a6',
    borderRounding: '7px'
}

export const lightTheme = {
    body: '#FFFFFF',
    bodyLighter: '#EBEBEB',
    bodyLightest: '#D6D6D6',
    buttonPrimary: '#00E092',
    buttonSecondary: '#7A0000',
    buttonDisabled: '#828282',
    modalShadow: 'rgba(0,0,0,0.5)',
    themeToggle: '#222B30',
    textMain: '#080B0C',
    textAlt: '#586F7C',
    accentColor: '#00E092',
    borderRounding: '7px'
}

export const defaultTheme = { 
    body: 'red',
    text: 'green',
}