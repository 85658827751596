import React from 'react';
import styled from 'styled-components';

interface Props {
    height?: string;
    width?: string;
    text: string;
    primary?: boolean;
    secondary?: boolean;
    theme: string;
    className?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonObj = styled.button<Pick<Props, 'height' | 'width' | 'primary' | 'secondary' | 'disabled'> >`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};
    border: 0px;
    border-radius: ${({ theme }) => theme.borderRounding};
    font-weight: bold;
    cursor: pointer;

    ${({ primary, theme, disabled}) => primary && `
        background-color: ${disabled ? theme.buttonDisabled : theme.buttonPrimary};
        color: ${theme.textMain};
    `}

    ${({ secondary, theme, disabled }) => secondary && `
        background-color: ${disabled ? theme.buttonDisabled : theme.buttonSecondary};
        color: ${theme.textMain};
    `}
`

const Button:React.FC<Props> = ( props ) => {
    return (
        <ButtonObj onClick={props.onClick} disabled={props.disabled} className={props.className} width={props.width} height={props.height} secondary={props.secondary} primary={props.primary}>{props.text}</ButtonObj>
    )
}

export default Button;