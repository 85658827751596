import { useWeb3 } from '@3rdweb/hooks';
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Sun from '../Icons/sun.png'
import Moon from '../Icons/moon.png'


interface Props { 
    theme: string;
    // toggleTheme: (event: React.MouseEvent<HTMLButtonElement>) => void;
    selected?: boolean;
    visible?: boolean;
    connect: (connector) => void;
}

const Container = styled.div`
    min-width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
`

const ConnectButton = styled.button`
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.textMain};
    border: 3px solid ${({ theme }) => theme.accentColor}; 
    border-radius: ${({ theme }) => theme.borderRounding};
    width: 115px;
    height: 45px;
    padding: 10px;
    font-weight: bold;
    margin-top: 10px;
    margin-right: 20px;
`
const ThemeToggle = styled.button`
    height: 31px;
    width: 60px;
    border: 0px;
    border-radius: 10px;
    color: ${({ theme }) => theme.body};
    margin-top: 17.5px;
    display: flex;
    margin-right: 20px;
`
const SunImage = styled.img<Pick<Props, 'selected' >>`
    height: 30px;
    padding: 5px;
    margin-left: -5px;
    margin-top: -1px;
    border-radius: 10px;

    @media only screen and (max-width: 500px) {
        margin-left: -10px;
        margin-top: 0px;
     }

    ${({ selected, theme }) => selected && `
        background-color: ${theme.accentColor};
    `}
`
const MoonImage = styled.img<Pick<Props, 'selected' >>`
    height: 30px;
    margin-top: -1px;
    padding: 5px;
    border-radius: 10px;

    @media only screen and (max-width: 500px) {
        margin-top: 0px;
    }

    ${({ selected, theme }) => selected && `
        background-color: ${theme.accentColor};
    `}
`
// const ToggleImageContainer = styled.div`
//     height: 28px;
//     width: 28px;
//     margin-left: -5px;
//     border-radius: ${({ theme }) => theme.borderRounding};

//     ${({ selected, theme }) => selected && `
//         background-color: ${theme.accentColor};
//     `}
// `
const ConnectContainer = styled.div<Pick<Props, 'visible'>>`
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100000000000000000;
    display: ${props => props.visible ? 'flex' : 'none'}
`
const InnerContainer = styled.div`
    max-width: 400px;
    max-height: 150px;
    display: flex;
    background-color: ${props => props.theme.body};
    border-radius: 10px;
    border: 2px solid ${props => props.theme.accentColor};
    margin-top: 250px;
    -webkit-box-shadow: 5px 5px 5000px 5000px rgba(0,0,0,0.23); 
    box-shadow: 5px 5px 5000px 5000px ${({ theme }) => theme.modalShadow};
`
const ItemContainer = styled.div`
    width: 50%;
    display: flex;
    flex-wrap: wrap;
`
const Icon = styled.img`
    width: 125px;
    height: 125px;
    margin: 15px;
`

const Nav: React.FC<Props> = ( props ) => {
    const { address, connectWallet, provider, chainId } = useWeb3();
    const [toggleModal, setToggleModal] = useState(false);

    const handleConnect = async (connector) => {
        props.connect(connector);
        setToggleModal(false);
    }

    return(
        <Container>
            {/* <ThemeToggle onClick={props.toggleTheme}>
                    <SunImage src={Sun} selected={props.theme === 'dark' ? false : true}/>
                    <MoonImage src={Moon} selected={props.theme === 'light' ? false : true}/>
            </ThemeToggle> */}
            <ConnectContainer visible={toggleModal}>
                <InnerContainer>
                    <ItemContainer>
                        <Icon onClick={() => handleConnect('injected')} src='metamask.png'/>
                    </ItemContainer>
                    <ItemContainer>
                        <Icon onClick={() => handleConnect('walletconnect')} src='walletconnect.png'/>
                    </ItemContainer>
                </InnerContainer>
            </ConnectContainer>
            <ConnectButton onClick={() => setToggleModal(true)}>{address != null ? address.substring(0, 6) + '...' + address.substring(38,42) : 'CONNECT'}</ConnectButton>
        </Container>
    )
}

export default Nav;